import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Post from './post';
import { WHITE } from '../../constants/colors';
import {
  CONTAINER_LAPTOP_WIDTH, CONTAINER_WIDTH, MOBILE, TABLET, media, LAPTOP,
} from '../../constants/screens';

const Posts = ({
  posts, domain, protocol, shortname,
}) => (
  <Container>
    <Content>
      <SortWrapper>
        {/* <Sort /> */}
        <Title>
          Recent posts
        </Title>
      </SortWrapper>
      <PostList>
        {posts.map(({ node: post }) => (
          <Post
            post={post}
            key={post.id}
            domain={domain}
            protocol={protocol}
            shortname={shortname}
          />
        ))}
      </PostList>
    </Content>
  </Container>
);

Posts.propTypes = {
  posts: PropTypes.instanceOf(Array).isRequired,
  domain: PropTypes.string.isRequired,
  protocol: PropTypes.string.isRequired,
  shortname: PropTypes.string.isRequired,
};

const Container = styled.div`
  background: ${WHITE};
  padding: 80px 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 60px 40px 40px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 70px 40px 50px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    padding: 70px 25px 50px;
  }
`;

const PostList = styled.ul`
  
`;

const SortWrapper = styled.div`
  @media screen and (max-width: ${TABLET}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 70px;
    flex-direction: row;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    margin-bottom: 50px;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: ${CONTAINER_WIDTH};
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `}
`;

const Title = styled.h3`
  text-transform: uppercase;
  font-size: 30px;
  margin-bottom: 90px;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 22px;
    margin-bottom: 60px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    font-size: 26px;
    margin-bottom: 0;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
`;

export default Posts;
