import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { BLUE } from '../../constants/colors';
import { LAPTOP, MOBILE, TABLET } from '../../constants/screens';

const Categories = ({ categories }) => (
  <CategoriesContainer>
    {categories.map(({ name, id, slug }) => (
      <Category key={id} to={`/blog/category/${slug}`}>
        {name}
      </Category>
    ))}
  </CategoriesContainer>
);

Categories.propTypes = {
  categories: PropTypes.instanceOf(Array).isRequired,
};


export default Categories;

const CategoriesContainer = styled.ul`
  margin-bottom: 30px;
  
  @media screen and (max-width: ${LAPTOP}) {
    margin-bottom: 20px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    margin-bottom: 15px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    margin-bottom: 10px;
  }
`;

const Category = styled(Link)`
  display: inline-block;
  color: ${BLUE};
  text-decoration: none;
  font-size: 16px;
  line-height: 1;
  border-radius: 16px;
  padding: 8px 23px;
  border: solid 2px ${BLUE};
  margin-right: 14px;
  margin-bottom: 10px;
  
  &:last-child {
    margin-right: 0;
  }
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 12px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 12px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 12px;
    padding: 4px 12px;
    border-radius: 14px;
    border-width: 1px;
  }
`;
