import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'gatsby-link';
import Img from 'gatsby-image';
import { pathOr } from 'ramda';
import Categories from './categories';
import {
  BLACK, BLUE, DARK_GRAY_2, WHITE,
} from '../../constants/colors';
import PostContent from './postContent';
import Socials from './socials';
import { LAPTOP, MOBILE, TABLET } from '../../constants/screens';

const Post = ({
  post: {
    slug,
    categories,
    title,
    date,
    wordpress_id: wordpressId,
    author: {
      name,
    },
    excerpt,
    featured_media: featuredMedia,
  },
  domain,
  protocol,
  shortname,
}) => {
  const altText = pathOr('', ['alt_text'], featuredMedia);
  const fluid = pathOr(null, ['localFile', 'childImageSharp', 'fluid'], featuredMedia);
  const url = `${protocol}://${domain}/blog/${slug}`;
  const blogPageUrl = `/blog/${slug}`;

  return (
    <Container>
      {fluid
        && (
        <Picture>
          <ImgLink to={blogPageUrl}>
            <Image fluid={fluid} alt={altText} />
          </ImgLink>
        </Picture>
        )
      }
      <Content>
        { categories && <Categories categories={categories} /> }
        <TitleLink to={blogPageUrl}>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
        </TitleLink>
        <Meta>
          {name}
          {' '}
·
          {' '}
          {date}
        </Meta>
        <PostContent content={excerpt} />
        <Button to={blogPageUrl}>Continue reading</Button>
        <Socials
          url={url}
          title={title}
          excerpt={excerpt}
          shortname={shortname}
          identifier={`${wordpressId} http://www.slashdata.co/?p=${wordpressId}`}
        />
      </Content>
    </Container>
  );
};

Post.propTypes = {
  post: PropTypes.shape().isRequired,
  domain: PropTypes.string.isRequired,
  protocol: PropTypes.string.isRequired,
  shortname: PropTypes.string.isRequired,
};

const Container = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  margin-bottom: 65px;
  box-shadow: 0px 6px 49.1px 4.9px rgba(220, 227, 238, 0.64);
  
  &:last-of-type {
    margin-bottom: 0;
  }
  
  @media screen and (max-width: ${LAPTOP}) {
    margin-bottom: 45px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    margin-bottom: 40px;
    flex-direction: column;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    margin-bottom: 30px;
  }
`;

const Button = styled(Link)`
  display: inline-block;
  border: 2px solid ${BLUE};
  color: ${BLUE};
  font-weight: 500;
  border-radius: 24px;
  cursor: pointer;
  font-family: AvenirNextLTPro;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  background-color: ${WHITE};
  box-sizing: border-box;
  padding: 14px 20px;
  cursor: pointer;

  &:hover{
    background-color: ${BLUE};
    color: ${WHITE};
  }
  
  margin-bottom: 40px;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 13px;
    padding: 12px 16px;
    margin-bottom: 30px;
    border-radius: 21px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    font-size: 14px;
    padding: 12px 16px;
    margin-bottom: 30px;
    border-radius: 21px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 12px;
    padding: 10px 14px;
    border-radius: 18px;
    margin-bottom: 24px;
    border-width: 1px;
  }
`;

const Picture = styled.picture`
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-basis: 50%;
  flex-shrink: 0;
  
  > div {
    width: 100%;
  }
`;

const Content = styled.div`
  flex-basis: 50%;
  padding: 90px 90px 45px 60px;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 60px 60px 30px 35px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 70px 50px 30px 50px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    padding: 50px 35px 20px 35px;
  }
`;

const Title = styled.h2`
  font-size: 33px;
  font-weight: 900;
  color: ${BLACK};
  margin-bottom: 10px;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 24px;
    margin-bottom: 7px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    font-size: 28px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
`;

const Meta = styled.div`
  font-family: AvenirNextLTPro;
  font-size: 16px;
  font-weight: 500;
  color: ${DARK_GRAY_2};
  margin-bottom: 40px;
  
   @media screen and (max-width: ${LAPTOP}) {
    font-size: 14px;
    margin-bottom: 30px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    font-size: 14px;
    margin-bottom: 30px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 12px;
    margin-bottom: 20px;
  }
`;

const ImgLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: block;
  
  > div {
    height: 100%;
  }
`;

const Image = styled(Img)`
  > picture > img {
    object-fit: contain !important;
  }
`;

const TitleLink = styled(Link)`
  text-decoration: none;
`;


export default Post;
