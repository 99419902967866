import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { CommentCount } from 'disqus-react';
import Facebook from '../../svg/facebook-icon.svg';
import Twitter from '../../svg/twitter-icon.svg';
import Linkedin from '../../svg/linkedin-icon.svg';
import CommentsIcon from '../../svg/comments-icon.svg';
import Share from '../../svg/share.svg';
import { BLUE, DARK_GRAY_2, MEDIUM_GRAY } from '../../constants/colors';
import { LAPTOP, MOBILE, TABLET } from '../../constants/screens';

const Socials = ({
  url, title, excerpt, shortname, identifier,
}) => (
  <Container>
    <Comments>
      <CommentsIcon />
      <Counter
        shortname={shortname}
        config={{
          title,
          identifier,
          url,
        }}
      />
    </Comments>
    <ShareContainer>
      <Icons>
        <FacebookShareButton
          url={url}
          quote={title}
        >
          <Icon style={{ transitionDelay: '0.08s' }} isFacebook>
            <Facebook />
          </Icon>
        </FacebookShareButton>
        <TwitterShareButton
          url={url}
          title={title}
          via="SlashDataHQ"
        >
          <Icon style={{ transitionDelay: '0.04s' }}>
            <Twitter />
          </Icon>
        </TwitterShareButton>
        <LinkedinShareButton
          url={url}
          title={title}
          description={excerpt}
        >
          <Icon>
            <Linkedin />
          </Icon>
        </LinkedinShareButton>
      </Icons>
      <ShareIcon />
    </ShareContainer>
  </Container>
);

Socials.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  shortname: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
};


export default Socials;

const Container = styled.div`
  padding: 30px 0;
  border-top: 2px solid ${MEDIUM_GRAY};
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 20px 0 0;
  }

  @media screen and (max-width: ${TABLET}) {
    padding: 30px 0 0;
  }

  @media screen and (max-width: ${MOBILE}) {
    padding: 24px 0 0;
    border-width: 1px;
  }
`;

const Comments = styled.div`
  display: flex;
  align-items: center;
  color: ${DARK_GRAY_2};
  font-size: 19px;
  
  & svg {
    width: 16px;
    height: 14px;
    margin-right: 10px;
  }
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 16px;
  }

  @media screen and (max-width: ${MOBILE}) {
    font-size: 14px;
  }
`;

const ShareContainer = styled.div`
  display: flex;
  cursor: pointer;

  &:not(:hover) div[role] {
    outline: none;
  }
`;


const ShareIcon = styled(Share)`
  height: 30px;
  width: 30px;
  transform: rotate(0deg);
  transition-property: transform;
  transition-duration: 0.2s;

  ${ShareContainer}:hover & {
    transform: rotate(180deg);

    & path {
      fill: ${MEDIUM_GRAY};
    }
  }

  & path {
    fill: ${BLUE};
    transition-property: fill;
    transition-duration: 0.2s;
  }

 @media screen and (max-width: ${LAPTOP}) {
    height: 24px;
    width: 24px;
  }

  @media screen and (max-width: ${MOBILE}) {
    height: 18px;
    width: 18px;
  }
`;

const Icons = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 15px;
  }
`;

const Icon = styled.span`
  cursor: pointer;
  display: block;
  height: 30px;
  width: ${props => (props.isFacebook ? '15px' : '30px')};
  padding: 0;
  transform: scale(0.8);
  opacity: 0;
  transition-property: transform, opacity, filter;
  transition-duration: 0.2s;
  transition-delay: 0, 0, 0.2s;

  ${ShareContainer}:hover & {
    transform: scale(1);
    opacity: 1;
  }

  & svg {
    height: 100%;
    width: 100%;
    
    & path {
      fill: ${BLUE};
    }
  }

  @media screen and (max-width: ${LAPTOP}) {
    height: 22px;
    width: ${props => (props.isFacebook ? '11px' : '22px')};

  }

  @media screen and (max-width: ${MOBILE}) {
    height: 16px;
    width: ${props => (props.isFacebook ? '8px' : '16px')};
    margin-right: 10px;
  }

`;

const Counter = styled(CommentCount)`
`;
