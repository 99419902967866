import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'gatsby-link';
import { times, pathOr } from 'ramda';
import Paginator from 'paginator';
import { BLUE, DARK_GRAY_2, WHITE } from '../../constants/colors';
import Arrow from '../../svg/arrow-back.svg';
import { LAPTOP, MOBILE, TABLET } from '../../constants/screens';

const Pagination = ({
  index, first, last, pageCount, category,
}) => {
  let previousUrl = (index - 1).toString();
  const nextUrl = (index + 1).toString();

  if (previousUrl === '1') { previousUrl = ''; }

  const slug = pathOr(null, ['slug'], category);
  const urlPrefix = !slug ? '/blog/' : `/blog/category/${slug}/`;

  const pagination = new Paginator(4, 5);
  const paginationInfo = pagination.build(4 * pageCount, index);
  const { first_page: firstPage, last_page: lastPage } = paginationInfo;
  const items = times(i => i + firstPage, lastPage - firstPage + 1);

  return (
    <Container>
      <NavLink disabled={first} url={urlPrefix + previousUrl}>
        <ArrowIcon next={false} />
        <Text>Previous</Text>
      </NavLink>
      {items.map(item => (
        <PaginationItem
          to={`${urlPrefix}${item === 1 ? '' : item}`}
          key={item}
          current={item === index}
        >
          {item}
        </PaginationItem>
      ))}
      <NavLink disabled={last} url={urlPrefix + nextUrl}>
        <Text>Next</Text>
        <ArrowIcon next />
      </NavLink>
    </Container>
  );
};

const NavLink = ({ disabled, url, children }) => {
  if (!disabled) {
    return <StyledLink to={url}>{children}</StyledLink>;
  }
  return null;
};

Pagination.propTypes = {
  index: PropTypes.number.isRequired,
  first: PropTypes.bool.isRequired,
  last: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  category: PropTypes.shape(),
};

Pagination.defaultProps = {
  category: {},
};

NavLink.propTypes = {
  disabled: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const Container = styled.div`
  background: ${WHITE};
  display: flex;
  align-items: center;
  padding: 120px 5% 70px;
  justify-content: center;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 20px 40px 50px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    padding: 5px 25px 90px;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 5px;
  color: ${DARK_GRAY_2};
  text-decoration: none;
  font-size: 24px;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 18px;
  }
`;

// eslint-disable-next-line
const PaginationItem = styled(({ current, ...rest }) => <Link { ...rest }/>)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 24px;
  color: ${props => (props.current ? WHITE : DARK_GRAY_2)};
  background: ${props => (props.current ? BLUE : 'transparent')};
  height: 42px;
  width: 42px;
  border-radius: 50%;
  margin: 0 10px;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 19px;
    height: 33px;
    width: 33px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 16px;
    height: 27px;
    width: 27px;
    margin: 0 5px;
  }
`;

// eslint-disable-next-line
const ArrowIcon = styled(({ next, ...rest }) => <Arrow { ...rest }/>)`
  width: 25px;
  height: 25px;
  margin: 0 10px; 
  transform: ${({ next }) => (next ? 'rotate(180deg)' : '')};
  
  & path {
    fill: ${DARK_GRAY_2};
  }
  
  @media screen and (max-width: ${LAPTOP}) {
    width: 19px;
    height: 19px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    width: 16px;
    height: 16px;
  }
`;

const Text = styled.span`
  @media screen and (max-width: ${TABLET}) {
    display: none;
  }
`;

export default Pagination;
