import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { DARK_BLUE_GRAY, DARK_BLUE, BLUE } from '../../constants/colors';
import { LAPTOP, TABLET } from '../../constants/screens';

const Introduction = ({ image: { childImageSharp: { sizes } } }) => (
  <IntroductionContainer>
    <ImgWrapper>
      <Img sizes={sizes} />
    </ImgWrapper>
  </IntroductionContainer>
);

Introduction.propTypes = {
  image: PropTypes.shape().isRequired,
};

const IntroductionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 300px 5% 125px;
  box-sizing: border-box;
  background-image: linear-gradient(126deg, ${DARK_BLUE_GRAY}, ${DARK_BLUE} 46%, ${BLUE});
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 200px 10% 120px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 230px 10% 150px;
  }
`;

const ImgWrapper = styled.div`
  width: 70%;
  max-width: 750px;
  
  @media screen and (max-width: ${LAPTOP}) {
    max-width: 600px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    width: 100%;
  }
`;

export default Introduction;
