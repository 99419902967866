import React, { Component } from 'react';
import styled from 'styled-components';
import { compose } from 'ramda';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { Introduction, Posts, Pagination } from '../components/blog';
import { LIGHT_SCHEME } from '../constants/colors';
import withStickyHeader from '../enhancers/withStickyHeader';
import withCustomLayout from '../enhancers/withCustomLayout';

// eslint-disable-next-line
class BlogInner extends Component {
  render() {
    const {
      pathContext: {
        group,
        additionalContext: {
          category,
          domain,
          protocol,
          shortname,
        },
      }, pathContext,
      data: { image },
    } = this.props;

    return (
      <Page>
        <Introduction image={image} />
        <Posts posts={group} domain={domain} protocol={protocol} shortname={shortname} />
        <Pagination {...pathContext} category={category} />
      </Page>
    );
  }
}

BlogInner.propTypes = {
  pathContext: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
};

const Page = styled.div`
  overflow-x: hidden;
`;

const Blog = compose(
  withStickyHeader({ isBlog: true }),
  withCustomLayout({ scheme: LIGHT_SCHEME }),
)(BlogInner);

// eslint-disable-next-line
export default ({ data, ...props }) => (
  <StaticQuery
    query={graphql`
      query BlogPage {
        image: file(relativePath: { regex: "/blog-ill/" }) {
          childImageSharp {
            sizes(maxWidth: 750) {
              ...GatsbyImageSharpSizes_noBase64
            }
          }
        }
      }
    `}
    render={d => <Blog {...props} data={{ ...data, ...d }} />}
  />
);
