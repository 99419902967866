import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  BLACK, DARK_GRAY_2, LIGHT_GRAY_2, MEDIUM_GRAY,
} from '../../constants/colors';
import { LAPTOP, MOBILE, TABLET } from '../../constants/screens';

const PostContent = ({ content }) => (
  <Container dangerouslySetInnerHTML={{ __html: content }} />
);

PostContent.propTypes = {
  content: PropTypes.string.isRequired,
};

const Container = styled.section`
  font-size: 21px;
  line-height: 1.4;
  margin-bottom: 30px;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    font-size: 16px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 14px;
    margin-bottom: 25px;
  }
  
  h1 {
    font-family: BrandonGrotesque;
    font-size: 1.9em;
    margin-bottom: 2.6em;
    margin-top: 3em;
  }
  
  h2 {
    font-family: BrandonGrotesque;
    font-size: 1.57em;
    margin-bottom: 2.2em;
    margin-top: 2.6em;
  }
  
  h3 {
    font-family: BrandonGrotesque;
    font-size: 1.5em;
    margin-bottom: 2.1em;
    margin-top: 2.5em;
  }
  
  h4 {
    font-family: BrandonGrotesque
    font-size: 1.42em;
    margin-bottom: 2em;
    margin-top: 2.4em;
  }
  
  h5 {
    font-size: 1.42em;
    font-family: AvenirNextLTPro;
    margin-bottom: 2em;
    margin-top: 2.4em;
  }
  
  h6 {
    font-family: AvenirNextLTPro;
    font-size: 1.2em;
    margin-bottom: 1.7em;
    margin-top: 2em;
  }
  
  p {
    font-size: 1em;
    margin-bottom: 1.4em;
  }
  
  b {
    font-family: AvenirNextLTProBold;
    font-weight: bold;
  }
  
  strong {
    font-family: AvenirNextLTProBold;
    font-weight: bold;
  }
  
  a {
    color: ${BLACK};
    text-decoration: none;
    border-bottom: 1px solid ${BLACK};
  }
  
  blockquote {
    border-left: 4px solid ${DARK_GRAY_2};
    border-left: 4px solid rgba(51, 51, 51, 0.7);
    color: ${DARK_GRAY_2};
    color: rgba(51, 51, 51, 0.7);
    font-size: 1.2em;
    font-style: italic;
    margin-bottom: 1.4em;
    padding-left: 0.6em;
  }
  
  blockquote p {
    margin-bottom: 1.4em;
  }
  
  blockquote > p:last-child {
    margin-bottom: 0;
  }
  
  blockquote cite,
  blockquote small {
    font-size: 0.8em;
    line-height: 1.4;
  }
  
  blockquote em,
  blockquote i,
  blockquote cite {
    font-style: normal;
  }
  
  blockquote strong,
  blockquote b {
    font-weight: 400;
  }
  
  address {
    font-style: italic;
    margin: 0 0 1.6em;
  }
  
  code,
  kbd,
  tt,
  var,
  samp,
  pre {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }
  
  pre {
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.01);
    border: 1px solid ${MEDIUM_GRAY};
    border: 1px solid rgba(51, 51, 51, 0.1);
    line-height: 1.2;
    margin-bottom: 1.4em;
    max-width: 100%;
    overflow: auto;
    padding: 0.8em;
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  
  abbr[title] {
    border-bottom: 1px dotted ${MEDIUM_GRAY};
    border-bottom: 1px dotted rgba(51, 51, 51, 0.1);
    cursor: help;
  }
  
  mark,
  ins {
    background-color: ${LIGHT_GRAY_2};
    text-decoration: none;
  }
  
  sup,
  sub {
    font-size: 75%;
    height: 0;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  
  sup {
    bottom: 1ex;
  }
  
  sub {
    top: .5ex;
  }
  
  small {
    font-size: 75%;
  }
  
  big {
    font-size: 125%;
  }
  
  
  /**
   * 4.0 Elements
   */
  
  hr {
    background-color: ${MEDIUM_GRAY};
    background-color: rgba(51, 51, 51, 0.1);
    border: 0;
    height: 1px;
    margin-bottom: 1.4em;
  }
  
  ul,
  ol {
    margin: 0 0 1.4em 1em;
  }
  
  ul {
    list-style: disc;
  }
  
  ol {
    list-style: decimal;
  }
  
  li > ul,
  li > ol {
    margin-bottom: 0;
  }
  
  dl {
    margin-bottom: 1.4em;
  }
  
  dt {
    font-weight: bold;
  }
  
  dd {
    margin-bottom: 1.4em;
  }
  
  table,
  th,
  td {
    border: 1px solid ${MEDIUM_GRAY};
    border: 1px solid rgba(51, 51, 51, 0.1);
  }
  
  table {
    border-collapse: separate;
    border-spacing: 0;
    border-width: 1px 0 0 1px;
    margin: 0 0 1.6em;
    table-layout: fixed; /* Prevents HTML tables from becoming too wide */
    width: 100%;
  }
  
  caption,
  th,
  td {
    font-weight: normal;
    text-align: left;
  }
  
  th {
    border-width: 0 1px 1px 0;
    font-weight: 700;
  }
  
  td {
    border-width: 0 1px 1px 0;
  }
  
  th, td {
    padding: 0.4em;
  }
  
  figure {
    margin: 0;
  }
  
  del {
    opacity: 0.8;
  }
  
  img {
    border: 0;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
  }
`;

export default PostContent;
